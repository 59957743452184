import { useEffect } from 'react'
import { hydrate } from 'react-dom'
import { RemixBrowser } from '@remix-run/react'
import { useLocation, useMatches } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import { CaptureConsole } from '@sentry/integrations'

Sentry.init({
  //
  dsn: 'https://16f4ae383168498dae6e6442868ba46a:1e26a0e33c614c4e9f6e68370b5781ca@o4504289463435264.ingest.sentry.io/4504289465204736',
  //
  tracesSampleRate: 1,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  //
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.remixRouterInstrumentation(
        useEffect,
        useLocation,
        useMatches
      ),
    }),
    new CaptureConsole({
      levels: ['error'],
    }),
    new Sentry.Replay(),
  ],
})

hydrate(<RemixBrowser />, document)

/**
 * Array.at polyfill for pre-2021 browsers
 */
function at(n: number) {
  // ToInteger() abstract op
  n = Math.trunc(n) || 0
  // Allow negative indexing from the end
  if (n < 0) n += this.length
  // OOB access is guaranteed to return undefined
  if (n < 0 || n >= this.length) return undefined
  // Otherwise, this is just normal property access
  return this[n]
}
const TypedArray = Reflect.getPrototypeOf(Int8Array)
for (const C of [Array, String, TypedArray]) {
  Object.defineProperty(C.prototype, 'at', {
    value: at,
    writable: true,
    enumerable: false,
    configurable: true,
  })
}
